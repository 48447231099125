<template>
  <component
    :is="addressFormComponent"
    v-model="internalAddress"
    :value="value"
    :available-countries="availableCountries"
    :disabled-fields="disabledFields"
    :required="required"
    :errors="errors"
    @address-updated="emitAddressUpdated"
    @input="updateInternalAddress"
  />
</template>

<script>
import { featureFlags } from '@/js/constants/featureFlags';
import AddressForm from './AddressForm.vue';
import AddressFormWithOceania from './AddressFormWithOceania.vue';

export default {
  name: 'AddressFormWrapper',
  components: {
    AddressForm,
    AddressFormWithOceania,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabledFields: {
      type: Array,
      required: false,
      default: () => [],
    },
    availableCountries: {
      type: Array,
      required: false,
      default: () => null,
    },
    required: {
      type: Array,
      required: false,
      default: () => [],
    },
    errors: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  emits: ['address-updated', 'input'],
  data() {
    return {
      internalAddress: { ...this.value },
    };
  },
  computed: {
    addressFormComponent() {
      return this.$store.getters.hasFeature(featureFlags.OCEANIA_ADDRESS_IMPROVEMENTS)
        ? 'AddressFormWithOceania'
        : 'AddressForm';
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        this.internalAddress = { ...newVal };
      },
    },
  },
  methods: {
    updateInternalAddress(newAddress) {
      this.internalAddress = newAddress;
      this.$emit('input', newAddress);
    },
    emitAddressUpdated(updatedAddress) {
      this.$emit('address-updated', updatedAddress);
    },
  },
};
</script>

import { formatCustomizationString } from '@/js/helpers/formatCustomizationString';

/**
 * Gets unique image URLs from a line item, with an optional limit on the number of images.
 * @param {Object} item - The line item to get the images from.
 * @param {number} maxImages - The maximum number of images to return.
 * @returns {string[]} - An array of image URLs.
 */
export const getSortedUniqueImageUrls = (item, maxImages = Infinity) => {
  if (maxImages === 0) return [];

  const imageUrls = new Set();
  if (item.image) imageUrls.add(item.image);

  const variantImages = [];
  const variantlessImages = [];
  
  // Collect variant and variantless images
  item.images?.forEach((image) => {
    if (!image.url) return;
    if (image.variantIds?.includes(item.variant_id)) {
      variantImages.push(image.url);
    } else if (!image.variantIds?.length) {
      variantlessImages.push(image.url);
    }
  });

  variantImages.forEach((url) => imageUrls.add(url));
  variantlessImages.forEach((url) => imageUrls.add(url));

  // Convert Set to Array and apply the maxImages limit
  return [...imageUrls].slice(0, maxImages);
};

/**
 * Gets the value of a product option from a line item.
 * Shopify stores the available options in the `options` array and the selected values in `option1`, `option2`, etc.
 * @param {Object} item - The line item
 * @param {string} optionName - The name of the option to retrieve.
 * @returns {string|null} - The value of the specified option, or null if not found.
 */
export const getProductOptionValue = (item, optionName) => {
  if (!item.options || !Array.isArray(item.options)) return null;
  
  const option = item.options.find(opt => opt.name.toLowerCase() === optionName.toLowerCase());
  if (!option) return null;

  const position = option.position;
  return item[`option${position}`] || null;
};

/**
 * Extracts text content from an HTML product description.
 * The description may contain HTML tags, so this function uses a DOMParser to extract the text content.
 * @param {string} description - The product description in HTML.
 * @returns {string} - The plain text content of the description.
 */
export const getDescriptionText = (description) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');

  return doc.body.textContent;
};

/**
 * Extracts values from product tags in the format `<prefix><?delimiter><key><delimiter><value>`.
 * Specially handles colors to support multiple colors.
 * @param {string} prefix - The prefix for the tags to search for.
 * @param {string} delimiter - The delimiter between the key and value.
 * @param {string[]} productTags - The array of product tags.
 * @returns {Object} - An object containing the extracted key-value pairs.
 */
export const extractPayloadValuesFromProductTags = (prefix, delimiter, productTags) => {
  const tagProperties = {};

  productTags.forEach((tag) => {
    if (tag.startsWith(prefix)) {
      const prefixLength = (tag[prefix.length] === delimiter) 
        ? prefix.length + 1 
        : prefix.length;
      const [key, value] = tag.slice(prefixLength).split(delimiter);

      if (!key || !value) return;

      const lowerCaseKey = key.toLowerCase();

      if (lowerCaseKey === 'color') {
        if (!tagProperties['colors']) {
          tagProperties['colors'] = [];
        }
        tagProperties['colors'].push(value);
      } else {
        tagProperties[lowerCaseKey] = value;
      }
    }
  });

  return tagProperties;
};

/**
 * Builds a title string from a format string and a set of variables.
 * The format string can contain placeholders in the format `{{variable}}`
 * and conditionals in the format `{{ variable ? "truthy text" : "falsy text" }}`.
 * @param {string} format - The format string with placeholders and conditionals.
 * @param {Object} variables - The set of variables to interpolate into the format string.
 * @returns {string} - The generated title string.
 */
export const buildTitle = (format, variables) => {
  return formatCustomizationString(format, variables).trim();
};

<template>
  <div
    v-if="poshmarkIsActive && !product.excluded.resell"
    :id="`poshmark-wrapper-${product.id}`"
    class="resell poshmark-wrapper"
    v-bind="additionalProps"
  />
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { featureFlags } from '@/js/constants/featureFlags';

export default {
  name: 'ResellWrapper',
  props: {
    product: {
      type: Object,
      required: true,
    },
    additionalProps: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const store = useStore();

    const poshmarkIsActive = computed(() => {
      const poshmarkIsAvailable = store.getters.hasFeature(featureFlags.LIST_WITH_POSHMARK);
      const poshmarkConfig = store.getters['settings']?.portalServicesConfigs?.poshmark ?? {};

      return poshmarkIsAvailable &&
        typeof window !== 'undefined' &&
        typeof window.PMResell !== 'undefined' &&
        !!poshmarkConfig.key &&
        !!poshmarkConfig.partner_id;
    });

    return {
      poshmarkIsActive
    };
  },
};
</script>
